export const DROPDOWN_LIST = [
  {
    name: '강의목록',
    href: `/my/courses`,
  },
  {
    name: '수강신청',
    href: `/my/apply-status`,
  },
  {
    name: '회원 정보 수정',
    href: '/my/profile',
  },
  {
    name: '비밀번호 변경',
    href: '/my/change-password',
  },
]
