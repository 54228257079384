/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-var-requires */
import { createRouterMiddleware, initialRouterState, routerReducer } from 'connected-next-router'
import { AppContext } from 'next/app'
import { Context, createWrapper, HYDRATE, MakeStore } from 'next-redux-wrapper'
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Reducer,
} from 'redux'
import logger from 'redux-logger'
import { createMigrate } from 'redux-persist'
import thunk, { ThunkDispatch } from 'redux-thunk'

import courseReducer from './courses'
import { migrations } from './migrations'
import applyReducer from './apply/reducer'
import authReducer from './auth'
import { configureStore } from '@reduxjs/toolkit'
import permissionReducer from './permission'

const combinedReducer = combineReducers({
  router: routerReducer,
  auth: authReducer,
  courses: courseReducer,
  apply: applyReducer,
  permission: permissionReducer,
})

const reducer = (state: RootState, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    }
    if (typeof window !== 'undefined') {
      nextState.router = state?.router
      nextState.auth = state?.auth
      nextState.apply = state?.apply
    }
    return nextState
  } else {
    return combinedReducer(state, action)
  }
}

const bindMiddleware = (middleware: Middleware[]) => {
  if (typeof window === 'undefined') {
    if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
      const { composeWithDevTools } = require('redux-devtools-extension')
      return composeWithDevTools(applyMiddleware(...middleware))
    }
  } else if (!/likelion.net/.test(window.location.host)) {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}
export const makeStore = () => {
  const routerMiddleware = createRouterMiddleware()
  // const { asPath } = (context as AppContext).ctx || Router.router || {}
  let initialState
  // if (asPath) initialState = { router: initialRouterState(asPath) }

  const isServer = typeof window === 'undefined'

  if (isServer) {
    return createStore(reducer as Reducer, initialState, bindMiddleware([thunk, routerMiddleware]))
  } else {
    const { persistStore, persistReducer } = require('redux-persist')
    const storage = require('redux-persist/lib/storage').default

    const persistConfig = {
      key: 'globWebPersistedStore',
      storage,
      version: 6,
      migrate: createMigrate(migrations, { debug: true }),
      whitelist: ['auth'],
    }
    const persistedReducer = persistReducer(persistConfig, reducer)

    // const store = createStore(
    //   persistedReducer,
    //   initialState,
    //   bindMiddleware([thunk, routerMiddleware]),
    // )
    const store = configureStore({
      reducer: persistedReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
    })
    // @ts-ignore
    store.__persistor = persistStore(store)

    return store
  }
}

export const wrapper = createWrapper(makeStore, {
  debug: process.env.NEXT_PUBLIC_NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof combinedReducer>
export type AppThunkDispatch = ThunkDispatch<RootState, void, AnyAction>
export type AppStore = ReturnType<typeof makeStore>
