import _ from 'lodash'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface IGclidQueryParams {
  children: ReactNode
}

interface IGclidContextState {
  gclid: string
  expire: number
}

const GclidContext = createContext<IGclidContextState | undefined>({
  gclid: '',
  expire: 0,
})

export const GclidQueryParamsProvider = ({ children }: IGclidQueryParams) => {
  const searchParams = useSearchParams()
  const [gclid, setGclid] = useState<IGclidContextState>()

  useEffect(() => {
    const savedGclid = localStorage.getItem('gclid') as string
    const getGclidQuery: any = {}

    searchParams.forEach((value, key) => {
      if (_.startsWith(key, 'gclid')) {
        getGclidQuery[key] = value
      }
    })

    if (_.isEmpty(savedGclid)) {
      if (!_.isEmpty(getGclidQuery)) {
        const gclidObj = {
          ...getGclidQuery,
          expire: Date.now() + 1000 * 60 * 60 * 24 * 50, // 50 days
        }
        localStorage.setItem('gclid', JSON.stringify(gclidObj))
        setGclid(gclidObj)
      }
    } else {
      const parsedGclid: IGclidContextState = JSON.parse(savedGclid)

      if (
        (_.isEmpty(getGclidQuery) || parsedGclid.gclid === getGclidQuery.gclid) &&
        Date.now() > parsedGclid.expire
      ) {
        localStorage.removeItem('gclid')
      }

      if (!_.isEmpty(getGclidQuery) && parsedGclid.gclid !== getGclidQuery.gclid) {
        const gclidObj = {
          ...getGclidQuery,
          expire: Date.now() + 1000 * 60 * 60 * 24 * 50, // 50 days
        }
        localStorage.setItem('gclid', JSON.stringify(gclidObj))
        setGclid(gclidObj)
      } else {
        setGclid(parsedGclid)
      }
    }
  }, [searchParams])

  return <GclidContext.Provider value={gclid}>{children}</GclidContext.Provider>
}

export const useGclidQueryParams = () => useContext(GclidContext)
