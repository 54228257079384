import Link from 'next/link'
import { forwardRef } from 'react'
import { DROPDOWN_LIST } from '@/constatns/header'
import { IProfile } from '@/features/auth/types'
import { useRouter } from 'next/navigation'

interface IDropdonwGNBProps {
  profile: IProfile
  setIsOpenDropdown: React.Dispatch<React.SetStateAction<boolean>>
}

const DropdownGNB = forwardRef<HTMLDivElement, IDropdonwGNBProps>(
  ({ profile, setIsOpenDropdown }, ref) => {
    const { name, email } = profile
    const router = useRouter()
    const onClickMenu = (href: string) => {
      router.push(href)
      setIsOpenDropdown(false)
    }
    return (
      <div ref={ref} className="absolute right-0 top-9 z-100 w-60 pt-7">
        <div className="overflow-hidden rounded-lg bg-white shadow-[6px_16px_60px_-26px_rgba(0,0,0,0.2)]">
          <header className="p-6">
            <h2 className="text-base font-semibold">{name}님</h2>
            <h3 className="text-sm font-normal text-neutral-400">{email}</h3>
          </header>
          <ul className="flex flex-col border-y border-gray-200 py-4">
            {DROPDOWN_LIST.map(({ name, href }) => (
              <li
                key={`dropdown_${name}`}
                className="cursor-pointer px-6 py-3"
                onClick={() => onClickMenu(href)}
              >
                <span className="text-sm">{name}</span>
              </li>
            ))}
          </ul>
          <footer className="px-5 pb-6 pt-5">
            <Link href="/oauth/logout" as="/oauth/logout">
              <span className="text-sm text-neutral-400">로그아웃</span>
            </Link>
          </footer>
        </div>
      </div>
    )
  },
)

DropdownGNB.displayName = 'DropdownGNB'
export default DropdownGNB
