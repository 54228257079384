import Image from 'next/image'
import Link from 'next/link'
import TagManager from 'react-gtm-module'

const NebecaTestQuickItem = () => {
  return (
    <Link
      href={'/card-test'}
      as={'/card-test'}
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'home_cardtest_btn_click',
            ...(sessionStorage.getItem('utmAndGclid') &&
              JSON.parse(sessionStorage.getItem('utmAndGclid') as string)),
          },
        })
      }}
      className="space-y-1.5"
    >
      <div className="relative flex w-full flex-col items-center justify-center">
        <Image
          alt="내배카_테스트"
          className="rounded-2xl"
          src="https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/follow_bnr_icon_card_test.svg"
          width={42}
          height={42}
        />
      </div>
      <p className="text-center text-sm font-medium text-[#1E1E1E]">
        나도 0원 수강
        <br />
        대상자?
      </p>
    </Link>
  )
}

export default NebecaTestQuickItem
