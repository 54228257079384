import Image from 'next/image'
import Link from 'next/link'

const MyApplyingQuickItem = ({ applyId }: { applyId: string }) => {
  return (
    <Link
      href={`${process.env.NEXT_PUBLIC_BASE_URL}/school/${applyId}/apply`}
      className="flex cursor-pointer flex-col items-center justify-center lg:gap-1.5"
    >
      <div className="relative flex w-full flex-col items-center justify-center">
        <div className="absolute -top-2 left-1/2 whitespace-nowrap rounded-full bg-yellow-450 px-1.5 py-0.5 text-[10px] font-semibold text-white">
          작성중
        </div>
        <Image
          alt="작성중인_지원서"
          className="rounded-2xl"
          src="https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/follow_bnr_icon_apply_sheet.svg"
          width={42}
          height={42}
        />
      </div>
      <p className="text-center text-sm font-medium text-[#1E1E1E]">
        작성중인
        <br />
        지원서
      </p>
    </Link>
  )
}

export default MyApplyingQuickItem
