import { useEffect, useState } from 'react'
import { useProfileQuery } from '@/features/auth/queries/useProfileQuery'
import { ICSSettings } from '../types/channeltalk'
import { usePathname } from 'next/navigation'
import { useTermsOfServiceQuery } from '@/features/auth/queries/useTermsOfServiceQuery'

type ChannelServiceReturnType = {
  boot: (settings: ICSSettings) => void
  setpage: (page: string) => void
  shutdown: () => void
  show: () => void
  hide: () => void
  open: (callback?: any) => void
  close: (callback?: any) => void
}

const detectBrowser = (navigator: Navigator) => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera'
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  } else if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge'
  } else {
    return 'Unknown'
  }
}

const detectOSName = (navigator: Navigator) => {
  let operatingSystem = 'Not known'
  if (navigator.userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS'
  }
  if (navigator.userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS'
  }
  if (navigator.userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS'
  }
  if (navigator.userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS'
  }

  return operatingSystem
}

const detectDeviceType = (navigator: Navigator) => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      navigator.userAgent,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const ChannelService = (): ChannelServiceReturnType => {
  const pathname = usePathname()
  const [isClient, setIsClient] = useState(false)
  const { data: profile } = useProfileQuery()
  const { data: termsOfService } = useTermsOfServiceQuery()

  useEffect(() => {
    setIsClient(true) // 클라이언트에서만 실행되도록 설정
  }, [])

  const loadScript = () => {
    const w = window
    if (w.ChannelIO) {
      ;(window.console.error || window.console.log || (() => {}))(
        'ChannelIO script included twice.',
      )
      return
    }

    const ch = function () {
      ch.c(arguments)
    } as any
    ch.q = []
    ch.c = (args: any) => {
      ch.q.push(args)
    }
    w.ChannelIO = ch

    const l = () => {
      if (w.ChannelIOInitialized) {
        return
      }
      w.ChannelIOInitialized = true
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      s.charset = 'UTF-8'
      const x = document.getElementsByTagName('script')[0]
      x.parentNode!.insertBefore(s, x)
    }

    if (document.readyState === 'complete') {
      l()
    } else if (window.attachEvent) {
      window.attachEvent('onload', l)
    } else {
      window.addEventListener('DOMContentLoaded', l, false)
      window.addEventListener('load', l, false)
    }
  }

  const boot = (settings: ICSSettings) => {
    if (window.ChannelIO) {
      window.ChannelIO('boot', settings)
    }
  }

  const setpage = (page: string) => {
    if (window.ChannelIO) {
      window.ChannelIO('setPage', page)
    }
  }

  const shutdown = () => {
    if (window.ChannelIO) {
      window.ChannelIO('shutdown')
    }
  }

  const show = () => {
    if (window.ChannelIO) {
      window.ChannelIO('showMessenger')
    }
  }

  const hide = () => {
    if (window.ChannelIO) {
      window.ChannelIO('hideChannelButton')
    }
  }

  const open = (callback?: any) => {
    if (window.ChannelIO) {
      window.ChannelIO('onShowMessenger', callback)
    }
  }

  const close = (callback?: any) => {
    if (window.ChannelIO) {
      window.ChannelIO('onHideMessenger', callback)
    }
  }

  useEffect(() => {
    if (isClient && !window.ChannelIO) {
      loadScript()
      const settings: ICSSettings = {
        pluginKey: process.env.NEXT_PUBLIC_CHANNEL_TALK_KEY as string,
        hideChannelButtonOnBoot: true,
        createdAt: new Date(),
        web: {
          browserName: detectBrowser(navigator),
          osName: detectOSName(navigator),
          device: detectDeviceType(navigator),
        },
      }

      if (profile && termsOfService) {
        const { id, name, email, phoneNo: mobileNumber } = profile
        const { sms, email: marketingAgreementEmail } = termsOfService.marketingAgreement
        settings.memberId = `${id}`
        settings.profile = {
          name,
          email,
          mobileNumber,
          referrer: pathname,
        }
        settings.unsubscribeEmail = marketingAgreementEmail
        settings.unsubscribeTexting = sms
      }

      boot(settings)

      setpage('https://bootcamp.likelion.net')
    }
  }, [isClient, profile, termsOfService, pathname]) // 의존성 배열에 필요한 변수를

  return {
    boot,
    setpage,
    shutdown,
    show,
    hide,
    open,
    close,
  }
}

export default ChannelService
