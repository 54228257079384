import _ from 'lodash'
import { useSearchParams } from 'next/navigation'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
interface IUtmQueryParams {
  children: ReactNode
}
interface IUtmContextState {
  utm_campaign: string
  utm_content: string
  utm_medium: string
  utm_source: string
  utm_term: string
}

const UtmAndGclidContext = createContext<IUtmContextState | undefined>({
  utm_campaign: '',
  utm_content: '',
  utm_medium: '',
  utm_source: '',
  utm_term: '',
})

export const UtmAndGclidQueryParamsProvider = ({ children }: IUtmQueryParams) => {
  const searchParams = useSearchParams()
  const [utmAndGclid, setUtmAndGclid] = useState<IUtmContextState>()

  useEffect(() => {
    const getUtm: any = {}
    searchParams.forEach((value, key) => {
      if (_.startsWith(key, 'utm') || _.startsWith(key, 'gclid')) {
        getUtm[key] = value
      }
    })

    if (!_.isEmpty(getUtm)) {
      sessionStorage.setItem('utmAndGclid', JSON.stringify(getUtm))
      setUtmAndGclid(getUtm)
    } else {
      sessionStorage.removeItem('utmAndGclid')
      setUtmAndGclid(undefined)
    }
  }, [searchParams])

  return <UtmAndGclidContext.Provider value={utmAndGclid}>{children}</UtmAndGclidContext.Provider>
}

export const useUtmAndGclidQueryParams = () => useContext(UtmAndGclidContext)
