'use client'

import { useEffect } from 'react'

const NaverScriptWrapper = () => {
  useEffect(() => {
    const naverScript = document.createElement('script')
    naverScript.type = 'text/javascript'
    naverScript.async = true
    naverScript.src = '//wcs.naver.net/wcslog.js'

    naverScript.onload = () => {
      var _nasa = {}
      if (!window.wcs) var wcs_add = {}
      if (window.wcs) {
        window.wcs_add = { wa: 's_4375ae5d5e4b' }
        window.wcs.inflow('bootcamp.likelion.net')
        window.wcs_do(_nasa)
        var _nasa = {}
      }
    }
    document.head.appendChild(naverScript)

    return () => {
      document.head.removeChild(naverScript)
    }
  }, [])

  return null
}

export default NaverScriptWrapper
