type ChatbotInstance = {
  closeChat: () => void
  showChatButton: () => void
  hideChatButton: () => void
}

const BebridgeAIChatbot = (userId: string): Promise<ChatbotInstance | null> => {
  return new Promise(resolve => {
    if (typeof window !== 'undefined') {
      const loadSDK = () => {
        if (window.BebridgeAIChatbotInitialized) {
          resolve(window.BebridgeAIChatbotInstance || null)
          return
        }

        window.BebridgeAIChatbotInitialized = true

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src =
          process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? 'https://likelion.chatbot.test.slid.cc/bebridge-ai-chat-test.js'
            : 'https://chatbot.likelion.net/bebridge-ai-chat.js'
        script.charset = 'UTF-8'
        document.head.appendChild(script)

        script.onload = () => {
          if (window.BebridgeAIChatbot) {
            const { BebridgeAIChat } = window.BebridgeAIChatbot

            if (BebridgeAIChat) {
              BebridgeAIChat.init({
                pluginKey:
                  process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? '33f532b6-a719-4ff0-acf0-7e11663530b4'
                    : 'ddc53011-0845-402c-914c-a8484657fb6f',
              })

              const chatbotInstance = new BebridgeAIChat()

              chatbotInstance.render({
                userKey: userId,
                botKey:
                  process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? '520c01d2-656a-4852-84cb-4c42b2ac10b5'
                    : '0031fc10-5c13-4268-b7aa-333bc9fe9001',
                hideDefaultChatButton: true,
              })

              window.BebridgeAIChatbotInstance = chatbotInstance
              resolve(chatbotInstance)
            } else {
              console.error('BebridgeAIChat is not available.')
              resolve(null)
            }
          } else {
            console.error('BebridgeAIChatbot is not available on the window object.')
            resolve(null)
          }
        }
      }

      if (document.readyState === 'complete') {
        loadSDK()
      } else {
        window.addEventListener('load', loadSDK)
      }
    } else {
      console.error('Window object is not available.')
      resolve(null)
    }
  })
}

export default BebridgeAIChatbot
